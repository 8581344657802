.payment-popup {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999 !important;
}

.payment-message {
  background-color: #ffffff !important;
  position: relative;
  max-width: 800px;
  margin-left: 20%;
  padding: 0.5rem !important;
  height: 340px;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  overflow: auto;
  border-collapse: collapse;
  font-size: 0.75em;
  font-family: gotham-rounded, helvetica neue, helvetica !important;
  box-shadow: 0 0 100px rgba(0, 0, 0, 1);
}
