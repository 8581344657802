.feedbackContainer {
  display: flex;
  justify-content: center;
}

.feedbackCommentSubmit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div#smileys svg {
  -webkit-appearance: none;
  width: 45px;
  height: 45px;
  border: none;
  cursor: pointer;
  transition: border 0.2s ease;
  margin: 0 5px;
  transition: all 0.2s ease;
}
div#smileys svg {
  fill: gray;
}
div#smileys svg:focus {
  outline: 0;
}
div#smileys svg.happy:hover path {
  fill: green;
}
div#smileys svg.neutral:hover path {
  fill: orange;
}
div#smileys svg.sad:hover path {
  fill: red;
}
div#smileys svg.selectedMoodSad path {
  fill: red;
}
div#smileys svg.selectedMoodNeutral path {
  fill: orange;
}
div#smileys svg.selectedMoodHappy path {
  fill: green;
}
.feedbackTextArea {
  min-height: 25px;
  margin-top: 10px;
  min-width: 400px;
  width: 400px;
}
.feedbackButton {
  margin: 5px; 
  min-width: 400px;
  width: 400px;
}
@media (max-width: 400px) {
  .feedbackTextArea {
    min-height: 25px;
    margin-top: 10px;
    min-width: 300px;
    width: 300px;
  }
  .feedbackButton {
    margin: 5px; 
    min-width: 300px;
    width: 300px;
  }
}