.h6Style {
    color: #3E416D;
    margin-top: 25px;
}

.paymentBox {
    background-color: #ffffff !important;
    position: relative;
    max-width: 600px;
    margin-left: 33%;
    padding: 0.5rem !important;
    height: 330px;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 8px;
    border: 1px solid #999;
    overflow: auto;
    border-collapse: collapse;
    font-size: 0.75em;
    font-family: gotham-rounded, helvetica neue, helvetica !important;
    box-shadow: 0 0 100px rgba(0, 0, 0, 1);
}