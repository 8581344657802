/* Article - https://bitsofco.de/github-contribution-graph-css-grid/ */

/* Grid-related CSS */

:root {
  --square-size: 15px;
  --square-gap: 5px;
  --week-width: calc(var(--square-size) + var(--square-gap));
}

.activity-graph .months {
  grid-area: months;
}
.activity-graph .days {
  grid-area: days;
}
.activity-graph .squares {
  grid-area: squares;
}

.activity-graph .graph {
  display: inline-grid;
  grid-template-areas:
    "empty months"
    "days squares";
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}

.activity-graph .months {
  display: grid;
  grid-template-columns:
    calc(var(--week-width) * 4) /* Jan */
    calc(var(--week-width) * 4) /* Feb */
    calc(var(--week-width) * 4); /* Mar */
}

.activity-graph .days,
.squares {
  display: grid;
  grid-gap: var(--square-gap);
  grid-template-rows: repeat(7, var(--square-size));
}

.activity-graph .squares {
  grid-auto-flow: column;
  grid-auto-columns: var(--square-size);
}

.activity-graph .graph {
}

.activity-graph .days li:nth-child(odd) {
  visibility: hidden;
}

.activity-graph .squares li {
  filter: drop-shadow(2px 1px 5px black);
  background-color: #ebedf0;
  border-radius: 2px;
  padding: 1px;
}

.activity-graph .squares li[data-level="1"] {
  background-color: #daf4a8;
}

.activity-graph .squares li[data-level="2"] {
  background-color: #9be9a8;
}

.activity-graph .squares li[data-level="3"] {
  background-color: #40c463;
}

.activity-graph .squares li[data-level="4"] {
  background-color: #30a14e;
}

.activity-graph .squares li[data-level="5"] {
  background-color: #216e39;
}

.activity-graph .ck ol,
ul,
dl {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.ck ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

.activity-graph {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  line-height: 12px;
}

.activity-graph-explainer-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
}

.activity-graph-explainer-text:hover {
  color: rgb(251, 226, 109);
}
.activity-graph-explainer-text:hover .activity-graph-help-icon {
  color: rgb(251, 226, 109);
}

.activity-graph-help-icon {
  font-size: 18px;
}

.activity-graph-explainer-container {
  display: flex;
  justify-content: flex-end;
}
