.icon1:before {
  color: #3e416d !important;
}

.admin-sidebar-text span {
  color: white;
}
.admin-sidebar-text i:before {
  color: white;
}
.admin-sidebar-text ul:before {
  color: whitesmoke;
}

.admin-sidebar-text {
  background-color: #1a5b99;
}
